<template>
  <div id="cooperativeResources">
    <el-button type="primary" class="add-cooperative smallBtn" @click="$router.push('/cooperativeAdd')"  size="small">添加合作资源</el-button>
    <!-- 表格 -->
    <el-table :data="cooperativeData" style="width: 100%">
      <el-table-column align="center" prop="image" label="机构" width="180">
        <template slot-scope="scope">
          <img :src="scope.row.images" alt="机构图片" width="100" height="60" />
        </template>
      </el-table-column>
      <el-table-column align="center" prop="name" label="机构名称/品牌名称"></el-table-column>
      <el-table-column align="center" prop="company_full_name" label="机构全称"></el-table-column>
      <el-table-column align="center" prop="city" label="机构位置">
        <template slot-scope="scope">
          <el-tag
            size="medium"
            v-for="(item,i) in scope.row.city"
            :key="i"
            style="margin-right: 5px"
          >{{item}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="operation" label="操作">
        <template slot-scope="scope">
          <el-button @click="hideCoo(scope.row)" type="primary" size="mini" class="editTab">
            <span v-if="scope.row.is_show == 1">显示</span>
            <span v-else>隐藏</span>
          </el-button>
          <el-button @click="delCoo(scope.row)" type="danger" size="mini" class="delTab">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页组件 -->
    <Page
      @currentPageChange="currentPageChange"
      :pageSize="pageData.per_page"
      :page="pageData.current_page"
      :total="pageData.total"
      style="text-align:center;margin-top:10px"
    ></Page>
  </div>
</template>
<script>
import Page from "@/components/PageComponents";
import { success } from "@/utils/notification";

export default {
  name: 'cooperativeResources',
  data() {
    return {
      id: Number(sessionStorage.organizationId),
      cooperativeData: [],
      page: 1,
      pageData: {
        //分页数据
        per_page: 0,
        current_page: 0,
        total: 0
      }
    }
  },
  components: {
    Page
  },
  methods: {
    //分页切换
    currentPageChange(p) {
      this.page = p;
      this.getCooList();
    },
    //隐藏机构
    hideCoo(row) {
      this.$http.put(this.URL.adminUrl.cooperationRow + row.id).then(res => {
        console.log(res.data.code)
        this.getCooList();
      })
    },
    //删除机构
    delCoo(row) {
      this.$confirm('此操作将删除该合作机构, 是否继续?', '提示', {
        customClass: 'cooperativeDialog',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.delete(this.URL.adminUrl.cooperationRow + row.id).then(res => {
          this.getCooList();
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        })
      }).catch(() => {
      });
    },
    //获取团队服务列表
    getCooList() {
      this.$http
        .get(this.URL.adminUrl.cooperationList + this.id, {
          params: {
            page: this.page
          }
        })
        .then(res => {
          this.cooperativeData = res.data.data.data;
          this.pageData = res.data.data.meta;
        });
    }
  },
  created() {
    this.getCooList();
  }
}
</script>
<style lang="less" scoped>
  .add-cooperative {
    float: right;
    margin: 10px 0 30px;
  }
</style>
<style lang="less">
  .cooperativeDialog{
    border: 0px!important;
    .el-message-box__btns{
      margin-bottom: 0px;
    }
		.el-message-box__close{
			color: #ffffff;
		}
  }
</style>
